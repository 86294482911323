const data = [
  {
    image: "/assets/image.jpeg",
    name: "Alexa Speaker",
    link: "https://www.amazon.in/generation-smart-speaker-powerful-Alexa/dp/B084KSRC9X/ref=sr_1_4?crid=YWI2X6JTRFLB&dib=eyJ2IjoiMSJ9.H75bGx4NvDWoLVAmp-SBBgUrAX6DaOKkhk1YjUuxaanfFudFAG6v64RmlLteoBD7Bn1yQBGE8eU9e0d5bptp6v_oOF69oA6IEh6aGoWAxM9y62G65brJzxP9OfRHXyCvXLi_xYzHo1OlFuSj06G1NpnyRCMBwXDfI2flLVlxvkwim6WEfpUhGl8E2tjtFNtvXz6dijdSZ1NXpXGwFAtF3nzn17T0Tq07FYexvyc7kQg.k1rBfYn5aSgwmNIKxUbGrrhRMJF_xQbXiiUjLcDJ1iI&dib_tag=se&keywords=alexa&qid=1718273674&sprefix=alex%2Caps%2C668&sr=8-4",
  },
  {
    image: "/assets/image2.jpeg",
    name: "SMART PLUG",
    link: "https://www.amazon.in/Wipro-Monitoring-Appliances-Microwave-Conditioners/dp/B08HN9Q2SZ/ref=sr_1_5_pp?crid=GGRMFZDZ3N86&dib=eyJ2IjoiMSJ9.e82k7OxEoWPlWVxYsukDJfBQeoDhVJieghaIAJUd4JbXXecJD7b0nur5sKYxa12eUQo3hsORX4mlgEv5z7N98cBAWYCYtOmF6cDLv5WQhfEtuSQTLOfB8SAVVw2ck-l2TNnwKCDzWLUBJbOBapEwPhAWvdBx4BeWmyhhH42kMlFMrJvvA5dN0uEdEZgTtUY0lv1NtFHx8c-6wXbd74rphjBJqK6IlTCqRUKSLkXqKMkEEIzlScDePa2D43eAa4_txwcmV_xAwDTcHiN9JU_KuxIATzS8fC6aIUMLje4Rzcw.E_e8H87YTQ78nM2r4Npwvk5-DKQGvXKwbjK_MA1j-c0&dib_tag=se&keywords=smart+plug&qid=1718273533&sprefix=smart+plu%2Caps%2C219&sr=8-5",
  },
  {
    image: "/assets/image.jpeg",
    name: "Alexa Speaker",
    link: "https://www.amazon.in/generation-smart-speaker-powerful-Alexa/dp/B084KSRC9X/ref=sr_1_4?crid=YWI2X6JTRFLB&dib=eyJ2IjoiMSJ9.H75bGx4NvDWoLVAmp-SBBgUrAX6DaOKkhk1YjUuxaanfFudFAG6v64RmlLteoBD7Bn1yQBGE8eU9e0d5bptp6v_oOF69oA6IEh6aGoWAxM9y62G65brJzxP9OfRHXyCvXLi_xYzHo1OlFuSj06G1NpnyRCMBwXDfI2flLVlxvkwim6WEfpUhGl8E2tjtFNtvXz6dijdSZ1NXpXGwFAtF3nzn17T0Tq07FYexvyc7kQg.k1rBfYn5aSgwmNIKxUbGrrhRMJF_xQbXiiUjLcDJ1iI&dib_tag=se&keywords=alexa&qid=1718273674&sprefix=alex%2Caps%2C668&sr=8-4",
  },
  {
    image: "/assets/image2.jpeg",
    name: "SMART PLUG",
    link: "https://www.amazon.in/Wipro-Monitoring-Appliances-Microwave-Conditioners/dp/B08HN9Q2SZ/ref=sr_1_5_pp?crid=GGRMFZDZ3N86&dib=eyJ2IjoiMSJ9.e82k7OxEoWPlWVxYsukDJfBQeoDhVJieghaIAJUd4JbXXecJD7b0nur5sKYxa12eUQo3hsORX4mlgEv5z7N98cBAWYCYtOmF6cDLv5WQhfEtuSQTLOfB8SAVVw2ck-l2TNnwKCDzWLUBJbOBapEwPhAWvdBx4BeWmyhhH42kMlFMrJvvA5dN0uEdEZgTtUY0lv1NtFHx8c-6wXbd74rphjBJqK6IlTCqRUKSLkXqKMkEEIzlScDePa2D43eAa4_txwcmV_xAwDTcHiN9JU_KuxIATzS8fC6aIUMLje4Rzcw.E_e8H87YTQ78nM2r4Npwvk5-DKQGvXKwbjK_MA1j-c0&dib_tag=se&keywords=smart+plug&qid=1718273533&sprefix=smart+plu%2Caps%2C219&sr=8-5",
  },
];
export default data;
