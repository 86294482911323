import "./App.css";

const CardComp = (props) => {
  return (
    <div className=" col-sm-12 col-md-4 col-lg-4">
      <a href={props?.data?.link} className="card-wrapper">
        <img src={props?.data?.image} className="App-logo" alt="logo" />
        <p>{props?.data?.name}</p>
      </a>
    </div>
  );
};

export default CardComp;
