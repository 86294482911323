import "./App.css";
import logo from "./assets/MR-logo-03.png";
import CardComp from "./CardComp.js";
import data from "./data";

const App = () => {
  return (
    <div className="magical-ring">
      <div className="logo-section" data-aos="fade-down">
        <img src={logo} alt="Magical Ring" className="logo" />
      </div>
      <div className="container overflow-hidden text-center" data-aos="fade-up">
        <div className="row g-4 row-data">
          {(data || []).map((list) => (
            <CardComp data={list} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default App;
